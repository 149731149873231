<template>
  <validation-observer ref="simpleRules">
    <b-card title="Görüşme Sil">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <interview-trash-interview-number-card />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <interview-trash-interview-notes-card />
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="submitStatus"
            @click="submitForm"
          >
            <feather-icon icon="Trash2Icon" />
            Sil
          </b-button></b-col>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>

import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InterviewTrashInterviewNumberCard from '@/views/ManagerTools/Components/InterviewTrash/interview_number.vue'
import InterviewTrashInterviewNotesCard from '@/views/ManagerTools/Components/InterviewTrash/notes.vue'

export default {
  name: 'InterviewTrashCard',
  components: {
    InterviewTrashInterviewNotesCard,
    InterviewTrashInterviewNumberCard,
    ValidationObserver,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['interviewTrash/dataItem']
    },
  },
  created() {
    localize('tr')
    this.$store.commit('interviewTrash/RESET_INTERVIEW_LIST')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('interviewTrash/interviewsTrash', this.itemData)
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Uyarı',
                  text: 'Görüşme silme işlemi başarılı.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$store.commit('interviewTrash/RESET_INTERVIEW_LIST')
              } else {
                this.$swal({
                  icon: 'danger',
                  title: 'Uyarı',
                  text: 'Görüşme silme işlemi başarısız. Görüşmeyi kontrol ediniz.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
