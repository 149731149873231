<template>
  <b-tabs
    pills
  >
    <b-tab active>
      <template #title>
        <feather-icon icon="MessageSquareIcon" />
        Görüşme Notları
      </template>
      <b-tabs
        pills
        fill
      >
        <b-tab
          active
          lazy
        >
          <template #title>
            <feather-icon icon="CheckSquareIcon" />
            <span>Görüşme Kapatma</span>
          </template>
          <interview-close-card />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <feather-icon icon="NavigationIcon" />
            <span>Görüşme Aktarma</span>
          </template>
          <interview-transfer-card />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <feather-icon icon="Trash2Icon" />
            <span>Görüşme Silme</span>
          </template>
          <interview-trash-card />
        </b-tab>
      </b-tabs>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ClipboardIcon" />
        Satış Föyleri
      </template>
      <b-tabs
        pills
        fill
      >
        <b-tab>
          <template #title>
            <feather-icon icon="RepeatIcon" />
            Satış Föyü Atama
          </template>
          index
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="XIcon" />
            Satış Föyü İptal
          </template>
          <cancel-sale-order-form-card />
        </b-tab>
      </b-tabs>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="GlobeIcon" />
        Web Talepleri
      </template>
      <b-tabs
        pills
        fill
      >
        <b-tab>
          <template #title>
            <feather-icon icon="RepeatIcon" />
            Yeniden Atama
          </template>
          index
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="Trash2Icon" />
            Talep Silme
          </template>
          index
        </b-tab>
      </b-tabs>
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import InterviewCloseCard from '@/views/ManagerTools/Components/interviewCloseCard.vue'
import InterviewTransferCard from '@/views/ManagerTools/Components/interviewTransferCard.vue'
import InterviewTrashCard from '@/views/ManagerTools/Components/InterviewTashCard.vue'
import CancelSaleOrderFormCard from '@/views/ManagerTools/Components/CancelSaleOrderForm.vue'

export default {
  name: 'UserOperations',
  components: {
    CancelSaleOrderFormCard,
    InterviewTrashCard,
    InterviewTransferCard,
    InterviewCloseCard,
    BTab,
    BTabs,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    // erişim kısıtlama işlemi
    if (this.userData.id !== '352' && this.userData.id !== '1') {
      this.$swal({
        icon: 'error',
        title: 'Uyarı',
        text: 'Bu alana giriş için yetkiniz bulunmuyor.',
        confirmButtonText: this.$store.state.app.removeResultClose,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      this.$router.push('/')
    }
  },
}
</script>
